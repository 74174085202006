<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div>
    <div class="wrap-flex">
      <el-select v-model="belong" placeholder="请选择赛程" size="mini" @change="handleChange">
        <el-option v-for="item in options" :key="item.name" :label="item.name" :value="item.name">
          <span>{{ item.name }}</span>
          <i v-if="item.is_over" style="color: red; margin-left: 20px" class="el-icon-warning"></i>
          <i v-else style="color: green; margin-left: 20px" class="el-icon-success"></i>
        </el-option>
      </el-select>
      <i ref="foldIcon" :style="{ transform: foldIcon ? 'rotate(180deg)' : 'rotate(0deg)' }"
        class="el-icon-s-unfold foldIcon" @click="foldOperation"></i>
      <div ref="operationList" class="operation_list">
        <el-button style="margin: 0 12px" type="primary" @click="handleSelect" size="mini">
          选择赛季抽签
          <i class="el-icon-coin"></i>
        </el-button>
        <exportDia :belong="belong"></exportDia>
        <el-button style="margin: 0 12px" size="mini" type="primary" @click="openDialog">发布赛程<i
            class="el-icon-view"></i></el-button>
        <el-button type="success" size="mini" style="margin: 0 12px" @click="refresh">刷新<i
            class="el-icon-refresh-left"></i></el-button>
        <div class="import_btn" @click="handleInput">
          批量导入
          <svg-icon style="margin-left: 3px" iconClass="import" width="14px" height="14px" color="#fff"></svg-icon>
        </div>
        <input type="file" id="ipt" style="display: none" @change="handleFileChange" />
      </div>
    </div>
    <!-- 数据主体 -->
    <el-scrollbar :native="false" wrapStyle="" wrapClass="" viewClass="" viewStyle="" :noresize="false" tag="section"
      v-loading="loading" element-loading-text="正在加载中......" style="height: calc(60vh - 50px); margin-bottom: 15px">
      <template v-if="scheduleData.length > 0">
        <asgTableCard v-for="(item, index) in scheduleData" :key="index">
          <template v-slot:header>
            <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 100%;
            ">
              <div style="display: flex">
                <el-tag size="small" type="primary" style="margin: 0 12px">{{
                  item.tag || '未设置赛标'
                  }}</el-tag>
                <p class="fontWeight">
                  {{ item.team1_name }}
                  <span style="color: rgb(71, 95, 184)">vs</span>
                  {{ item.team2_name }}
                </p>
              </div>
              <div>
                <i style="color: rgb(71, 95, 184); margin-right: 10px" class="el-icon-edit"
                  @click="updateGame(item)"></i>
                <i style="color: #f40; margin-right: 10px" class="el-icon-delete-solid" @click="delGame(item)"></i>
              </div>
            </div>
          </template>
          <template v-slot:content>
            <el-descriptions>
              <el-descriptions-item label="主场战队" :span="1">{{ item.team1_name }}（{{
                item.team1_piaoshu
                }}）</el-descriptions-item>
              <el-descriptions-item label="客场战队" :span="2">{{ item.team2_name }}（{{
                item.team2_piaoshu
                }}）</el-descriptions-item>
              <el-descriptions-item label="比赛时间">{{
                new Date(item.opentime) | parseTime("{y}-{m}-{d} {h}:{i}:{s}")
                }}</el-descriptions-item>
              <el-descriptions-item label="解说">
                {{ handleCommentary(item.commentary) }}
              </el-descriptions-item>
              <el-descriptions-item label="裁判/导播">{{
                item.referee
                }}</el-descriptions-item>
              <el-descriptions-item label="直播链接"><el-link v-if="item.bilibiliuri !== 'lose' && item.bilibiliuri"
                  :href="item.bilibiliuri" target="_blank" type="success">点击前往</el-link>
                <el-link v-else-if="item.bilibiliuri === 'lose'" type="info">回放丢失</el-link>
                <el-link v-else type="danger">回放尚未上传</el-link></el-descriptions-item>
              <el-descriptions-item label="获胜队伍"><span v-if="item.winteam === null">比赛进行中</span>
                <span class="winner" v-else><i class="el-icon-trophy"></i>{{ item.winteam
                  }}</span></el-descriptions-item>
              <el-descriptions-item label="属于">
                <el-tag size="mini" type="success">{{ item.belong }}</el-tag>
              </el-descriptions-item>
            </el-descriptions>
          </template>
        </asgTableCard>
      </template>
      <el-empty v-else description="暂无赛程数据"></el-empty>
    </el-scrollbar>
    <el-pagination style="float: right" @current-change="handleCurrentChange" :current-page.sync="currentPage"
      :page-size="pageSize" layout="prev, pager, next, jumper" :total="1000">
    </el-pagination>
    <!-- 表单 -->
    <el-dialog title="赛程信息" top="5vh" :visible.sync="dialogFormVisible" @open="setTeam" :close-on-click-modal="false">
      <el-scrollbar style="height: 60vh">
        <el-form :model="diaData">
          <el-row>
            <el-col :span="10">
              <el-form-item label="赛季标识">
                <el-select filterable size="small" style="margin-bottom: 10px" v-model="diaData.tag"
                  placeholder="请输入赛程标签">
                  <el-option v-for="(item, index) in tagOptions" :key="index" :label="item.name" :value="item.name">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="21">
              <el-form-item label="开始时间" :label-width="formLabelWidth">
                <el-input size="small" v-model="diaData.opentime" placeholder="2024-01-24T02:30:10.656Z" clearable>
                  <el-button slot="append" icon="el-icon-setting" @click="handleReset"></el-button>
                </el-input>
                <p>
                  <span><span style="color: red">严格按照这个格式输入</span>:
                    2024-01-24<span style="color: gray; font-weight: bold">T</span>02:30:10<span
                      style="color: gray; font-weight: bold">.656Z</span></span>
                </p>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <el-form-item label="赛程分类" :label-width="formLabelWidth">
                <el-input size="small" v-model="diaData.belong" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="主场战队" :label-width="formLabelWidth">
                <!-- <el-select size="small" filterable clearable value-key="id" v-model="diaData.team1_name"
                  placeholder="请选择战队1">
                  <el-option v-for="item in teamList" :key="item.id" :label="item.name" :value="item.name">
                  </el-option>
                </el-select> -->
                <el-input v-model="diaData.team1_name" size="small" placeholder="请选择战队1"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="客场战队" :label-width="formLabelWidth">
                <!-- <el-select size="small" filterable clearable value-key="id" v-model="diaData.team2_name"
                  placeholder="请选择战队2">
                  <el-option v-for="item in teamList" :key="item.id" :label="item.name" :value="item.name">
                  </el-option>
                </el-select> -->
                <el-input v-model="diaData.team2_name" size="small" placeholder="请选择战队2"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <el-form-item label="解说1" :label-width="formLabelWidth">
                <el-select size="small" filterable clearable value-key="id" v-model="diaData.comList[0]"
                  placeholder="请选择解说1">
                  <el-option v-for="item in commentaryOptions" :key="item.chinaname" :label="item.chinaname"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="解说2" :label-width="formLabelWidth">
                <el-select size="small" filterable clearable value-key="id" v-model="diaData.comList[1]"
                  placeholder="请选择解说1">
                  <el-option v-for="item in commentaryOptions" :key="item.chinaname" :label="item.chinaname"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="导播" :label-width="formLabelWidth">
                <el-select size="small" filterable clearable value-key="chinaname" v-model="diaData.referee"
                  placeholder="请选择导播或者裁判">
                  <el-option v-for="item in instructorOptions" :key="item.chinaname" :label="item.chinaname"
                    :value="item.chinaname">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="回放链接" :label-width="formLabelWidth">
                <el-input size="small" v-model="diaData.bilibiliuri" autocomplete="off">
                  <template slot="append">
                    <span style="cursor:pointer" @click="setLose">设置为回放丢失</span>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item label="赛选获胜队伍" :label-width="formLabelWidth">
              <el-col :span="8">
                <el-select size="small" v-model="winteam" clearable>
                  <el-option v-for="item in chooseTeam" :key="item.id" :label="item.value" :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="10">
                <el-button style="margin-left: 20px" type="primary" size="small"
                  @click="setWinner(diaData.id)">设置获胜队伍</el-button>
              </el-col>
            </el-form-item>
          </el-row>
        </el-form>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="updateSchedule">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 弹出框 -->
    <dialog-choose :eventName="belong" :showChoose.sync="showChoose" @refresh="handleInit"></dialog-choose>
  </div>
</template>

<script>
import {
  delSchedule,
  getSchedule,
  updateSchedule,
  setWinGame,
  importExcel,
} from "@/api/schedule/index";
import { getAllEvents } from "@/api/gameSeason/index";
import exportDia from "./exportDia.vue";
import DialogChoose from "./DialogChoose.vue";
import asgTableCard from "@/components/asg-table-card.vue";
import { getUserRoles } from "@/api/schedule/index";
import { getEnrollList } from "@/api/enroll/index.js"
import * as XLSX from "xlsx";
export default {
  name: "ScheduleTable",
  components: {
    exportDia,
    DialogChoose,
    asgTableCard,
  },
  data() {
    return {
      scheduleData: [],
      chooseTeam: [], //选择冠军队伍
      loading: false,
      foldIcon: false,
      dialogFormVisible: false,
      formLabelWidth: "200",
      diaData: {
        comList: [],
      },
      commentaryOptions: [], //解说选项
      instructorOptions: [], //导播选项
      teamList: [], //战队选项
      winteam: "",
      currentPage: 1,
      pageSize: 10,
      belong: "第五届ASG众创赛",
      options: [],
      tagOptions: [
        { name: "总决赛" },
        { name: "季军赛" },
        { name: "半决赛" },
        { name: "四强赛" },
        { name: "八强赛" },
        { name: "十六强" },
        { name: "常规赛" },
        { name: "海选赛" },
        { name: "高校赛" },
        { name: "众创赛" },
        { name: "神将赛" },
        { name: "淘汰赛" },
        { name: "擂台赛" },
      ],
      showChoose: false, //选择参赛队伍弹出框
    };
  },
  methods: {
    openDialog() {
      this.$emit("operation");
    },
    //获取赛程
    initSchedule(page, pagesize, belong) {
      this.loading = true;
      getSchedule(page, pagesize, belong)
        .then((res) => {
          this.scheduleData = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取赛季
    initSeason() {
      getAllEvents()
        .then((res) => {
          this.options = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSelect() {
      if (this.belong === "all" || !this.belong) {
        this.$message.error("请选择抽取的赛季");
      } else {
        this.showChoose = true;
      }
    },
    handleReset() {
      this.$set(this.diaData, "opentime", "1970-01-01T00:00:00.656Z");
    },
    setTeam() {
      this.chooseTeam = [];
      this.winteam = this.diaData.winteam || "暂未设置获胜队伍";
      this.chooseTeam.push({ value: this.diaData.team1_name, id: "1" });
      this.chooseTeam.push({ value: this.diaData.team2_name, id: "2" });
    },
    initGetCommentary() {
      let params = {
        opname: "Commentator",
      };
      getUserRoles(params)
        .then((res) => {
          this.commentaryOptions = [];
          this.commentaryOptions.push({ chinaname: "待定", id: 0 });
          this.commentaryOptions = this.commentaryOptions.concat(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    delGame(row) {
      this.$confirm("是否删除该赛程?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delSchedule(row.id)
          .then(() => {
            this.$message.success("删除成功！");
            this.initSchedule(this.currentPage, this.pageSize, this.belong);
          })
          .catch((err) => {
            this.$message.error(err);
          });
      });
    },
    refresh() {
      this.initSchedule(this.currentPage, this.pageSize, this.belong);
    },
    updateGame(row) {
      this.dialogFormVisible = true;
      let comList = JSON.parse(row.commentary);
      this.diaData = {
        ...row,
      };
      this.$set(this.diaData, "comList", comList);
    },
    updateSchedule() {
      const commentary = this.diaData.comList.map((item) => ({
        id: item.id,
        chinaname: item.chinaname,
      }));
      const filterArr = commentary.filter((v) => v.id && v.id !== 0);
      const info = {
        team1_name: this.diaData.team1_name,
        team2_name: this.diaData.team2_name,
        belong: this.diaData.belong,
        opentime: this.diaData.opentime,
        commentary: JSON.stringify(filterArr),
        bilibiliuri: this.diaData.bilibiliuri,
        referee: this.diaData.referee,
        tag: this.diaData.tag,
      };
      updateSchedule(this.diaData.id, info)
        .then(() => {
          this.$message.success("更新成功！");
          this.dialogFormVisible = false;
          this.initSchedule(this.currentPage, this.pageSize, this.belong);
        })
        .catch((err) => {
          if(err.response.data.code === 400){
            return this.$message.error('操作失败，无权访问');
          }
          this.$message.error('操作失败，后端服务器异常');
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.initSchedule(this.currentPage, this.pageSize, this.belong);
    },
    setWinner(id) {
      const loading = this.$loading({
        lock: true,
        text: "设置获胜者中......",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setWinGame(id, this.winteam)
        .then(() => {
          this.$message.success("设置成功！");
          loading.close();
          this.winteam = "";
          this.initSchedule(this.currentPage, this.pageSize, this.belong);
        })
        .catch(() => {
          this.winteam = "";
          loading.close();
          this.initSchedule(this.currentPage, this.pageSize, this.belong);
        });
      this.dialogFormVisible = false;
    },
    handleChange() {
      this.currentPage = 1;
      this.initSchedule(this.currentPage, this.pageSize, this.belong);
    },
    async initEnrollList() {
      try {
        const { data } = await getEnrollList(1, 128, "time", this.belong);
        this.teamList = data;
      } catch (error) {
        console.log(error)
      }
    },
    handleInit() {
      this.initSchedule(this.currentPage, this.pageSize, this.belong);
    },
    handleFileChange(e) {
      let file = e.target.files[0]; // 文件信息
      // 错误情况判断
      if (!file) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
        this.$message.error("上传格式不正确，请上传xls或者xlsx格式");
        return false;
      }
      const fileReader = new FileReader(); // 读取文件

      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result; // 获取结果
          // 获取所有表的信息
          const workbook = XLSX.read(data, {
            type: "binary", // 以字符编码的方式解析
            cellDates: true, // 将excel中日期类型数据，转化为标准日期格式，而不是默认的数字格式
          });
          // 获取第一张表的表名
          const exlname = workbook.SheetNames[0];
          // 转换成json数据
          const exl = XLSX.utils.sheet_to_json(workbook.Sheets[exlname]); // 生成json表格内容
          console.log("🐕 ~ exl:", exl);
          if (!exl.every((v) => v.opentime)) {
            this.$message.error("请检查开始时间是否填写！");
            return;
          }
          if (!exl.every((v) => v.referee)) {
            this.$message.error("请检查导播或者裁判是否填写！");
            return;
          }
          // 打印 ws 就可以看到读取出的表格数据
          const exportData = this.setExcelData(exl);
          console.log("🐕 ~ 解说数据格式", exportData);
          if (!exportData.every((data) => data.belong)) {
            this.$message.error("请检查每个赛季是否都填写了！");
            return;
          }
          const loading = this.$loading({
            lock: true,
            text: "正在导入数据中......",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.8)",
          });
          importExcel(exportData)
            .then(() => {
              this.clearInput();
              this.$message.success("导入成功！");
              this.initSchedule(this.currentPage, this.pageSize, this.belong);
              loading.close();
            })
            .catch((err) => {
              this.clearInput();
              this.$message.error(err);
              loading.close();
            });
          // 数据处理
        } catch (e) {
          this.clearInput();
          loading.close();
          this.$message.error("导入失败，请检查模板是否填写正确！");
          return false;
        }
      };
      fileReader.readAsBinaryString(file);
    },
    handleInput() {
      const input = document.getElementById("ipt");
      input.click();
    },
    clearInput() {
      const input = document.getElementById("ipt");
      input.value = "";
    },
    setExcelData(arr) {
      let newArr = arr.slice(2);
      newArr.forEach((item) => {
        if (item.commentary) {
          let containerArr = [];
          if (typeof item.commentary !== 'string') {
            item.commentary = item.commentary.toString();
          }
          const commentarys = item.commentary.split(",");
          for (let i = 0; i < commentarys.length; i++) {
            const findCom = this.commentaryOptions.find(
              (coms) => coms.chinaname === commentarys[i]
            );
            if (findCom) {
              containerArr.push(findCom);
            }
          }
          let mapData = containerArr.map((v) => ({
            id: v.id,
            chinaname: v.chinaname,
          }));
          item.commentary = JSON.stringify(mapData);
        }
      });
      return newArr;
    },

    // 折叠
    foldOperation() {
      this.foldIcon = !this.foldIcon;
      this.$refs.operationList.style.width = this.foldIcon ? "0" : "50vw";
    },
    // 处理
    handleCommentary(commentary) {
      return JSON.parse(commentary)
        .map((item) => item.chinaname)
        .join(",");
    },
    //获取导播列表
    initGetAnchor() {
      let params = {
        opname: "Anchor",
      };
      getUserRoles(params)
        .then((res) => {
          this.instructorOptions = [];
          this.instructorOptions.push({ chinaname: "待定" });
          this.instructorOptions = this.instructorOptions.concat(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 回放丢失
    setLose() {
      this.diaData.bilibiliuri = "lose";
    }
  },
  watch: {
    belong: {
      handler() {
        this.initEnrollList();
      }
    }
  },
  created() {
    this.initSchedule(this.currentPage, this.pageSize, this.belong);
    this.initGetCommentary();
    this.initSeason();
    this.initGetAnchor();
  },
};
</script>

<style scoped lang="less">
.winner {
  color: #cccc00;
}

.el-col {
  margin: 0 10px;
}

.wrap-flex {
  display: flex;
  align-items: center;
  margin: 12px 30px;
  height: 40px;
}

.fontWeight {
  font-weight: bold;
  font-size: 16px;
  margin: 0 12px;
}

.import_btn {
  display: flex;
  justify-content: center;
  margin: 0 12px;
  align-items: center;
  font-size: 13px;
  padding: 5px 12px;
  cursor: pointer;
  border-radius: 3px;
  color: #fff;
  background-color: #85ce61;

  &:hover {
    background-color: #9ce279;
  }
}

.operation_list {
  display: flex;
  justify-content: start;
  align-items: center;
  transition: 0.4s;
  width: 50vw;
  overflow: hidden;
}

.foldIcon {
  display: block;
  transition: 0.4s all;
  cursor: pointer;
  font-size: 18px;
  margin: 0 12px;
}

/deep/.el-descriptions__body {
  padding: 5px 24px;
}
</style>
